"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault")["default"];
Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "close", {
  enumerable: true,
  get: function get() {
    return _commands.close;
  }
});
Object.defineProperty(exports, "default", {
  enumerable: true,
  get: function get() {
    return _view["default"];
  }
});
Object.defineProperty(exports, "name", {
  enumerable: true,
  get: function get() {
    return _slice.name;
  }
});
Object.defineProperty(exports, "push", {
  enumerable: true,
  get: function get() {
    return _commands.push;
  }
});
Object.defineProperty(exports, "pushFail", {
  enumerable: true,
  get: function get() {
    return _commands.pushFail;
  }
});
Object.defineProperty(exports, "pushSuccess", {
  enumerable: true,
  get: function get() {
    return _commands.pushSuccess;
  }
});
Object.defineProperty(exports, "reducer", {
  enumerable: true,
  get: function get() {
    return _slice.reducer;
  }
});
Object.defineProperty(exports, "selectNotifications", {
  enumerable: true,
  get: function get() {
    return _slice.selectNotifications;
  }
});
var _view = _interopRequireDefault(require("./view"));
var _commands = require("./store/commands");
var _slice = require("./store/slice");