"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.openDialog = exports.closeDialog = void 0;
var _slice = require("./slice");
var openDialog = function openDialog(name) {
  var data = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
  return function (dispatch) {
    dispatch((0, _slice.openDialogAction)({
      name: name,
      data: data
    }));
  };
};
exports.openDialog = openDialog;
var closeDialog = function closeDialog(name) {
  return function (dispatch) {
    dispatch((0, _slice.closeDialogAction)());
  };
};
exports.closeDialog = closeDialog;