"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault")["default"];
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.selectNotifications = exports.resetStateAction = exports.reducer = exports.pushNotificationAction = exports.name = exports.closeNotificationAction = void 0;
var _toConsumableArray2 = _interopRequireDefault(require("@babel/runtime/helpers/esm/toConsumableArray"));
var _toolkit = require("@reduxjs/toolkit");
var REDUCER_NAME = 'package/push';
var initialState = {
  data: []
};
var slice = (0, _toolkit.createSlice)({
  name: REDUCER_NAME,
  initialState: initialState,
  reducers: {
    resetStateAction: function resetStateAction(state) {
      state['data'] = [];
    },
    closeNotificationAction: function closeNotificationAction(state, _ref) {
      var payload = _ref.payload;
      var index = state['data'].findIndex(function (item) {
        return item['uuid'] === payload;
      });
      state['data'] = [].concat((0, _toConsumableArray2["default"])(state['data'].slice(0, index)), (0, _toConsumableArray2["default"])(state['data'].slice(index + 1)));
    },
    pushNotificationAction: function pushNotificationAction(state, _ref2) {
      var payload = _ref2.payload;
      state['data'] = [].concat((0, _toConsumableArray2["default"])(state['data']), [payload]);
    }
  }
});
var _slice$actions = slice['actions'],
  resetStateAction = _slice$actions.resetStateAction,
  pushNotificationAction = _slice$actions.pushNotificationAction,
  closeNotificationAction = _slice$actions.closeNotificationAction;
exports.closeNotificationAction = closeNotificationAction;
exports.pushNotificationAction = pushNotificationAction;
exports.resetStateAction = resetStateAction;
var selectNotifications = function selectNotifications(state) {
  return state[REDUCER_NAME]['data'];
};
exports.selectNotifications = selectNotifications;
var name = slice['name'];
exports.name = name;
var reducer = slice['reducer'];
exports.reducer = reducer;