"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.pushSuccess = exports.pushFail = exports.push = exports.close = void 0;
var _utils = require("@helper/utils");
var _slice = require("./slice");
var close = function close(uuid) {
  return function (dispatch) {
    dispatch((0, _slice.closeNotificationAction)(uuid));
  };
};
exports.close = close;
var push = function push(data) {
  return function (dispatch) {
    data['uuid'] = (0, _utils.UUID)();
    dispatch((0, _slice.pushNotificationAction)(data));
  };
};
exports.push = push;
var pushFail = function pushFail(title, content) {
  return function (dispatch) {
    dispatch((0, _slice.pushNotificationAction)({
      title: title,
      content: content,
      autoClose: false,
      mode: 'danger',
      uuid: (0, _utils.UUID)()
    }));
  };
};
exports.pushFail = pushFail;
var pushSuccess = function pushSuccess(title, content) {
  return function (dispatch) {
    dispatch((0, _slice.pushNotificationAction)({
      title: title,
      content: content,
      autoClose: true,
      mode: 'success',
      uuid: (0, _utils.UUID)()
    }));
  };
};
exports.pushSuccess = pushSuccess;